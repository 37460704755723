import { useTranslation } from "react-i18next"
import { useReseller } from "../context/resellerContext"

const ResellerBar = () => {
  const [{ languageSelector, reseller, setAnalytics }, dispatchReseller] = useReseller()
  const { t } = useTranslation()
  const getFlagUrl = () =>
    `https://assets.bose.com/content/dam/Bose_DAM/Web/consumer_electronics/bose_global_portal/flags/${t(
      "composer.countryName",
    )}.png/_jcr_content/renditions/cq5dam.web.1280.1280.png`
  return (
    <div className="bottomBar" style={{ display: reseller ? "flex" : "none" }}>
      <div
        tabIndex={0}
        onClick={() => {
          dispatchReseller({ type: "showLanguageSelector" })
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            dispatchReseller({ type: "showLanguageSelector" })
          }
        }}
        className="languageSelector bottomContent"
        role="button"
        style={{ display: languageSelector ? "flex" : "none" }}>
        <h2>{t("composer.langName")}</h2> <img src={getFlagUrl()} alt="flag icon" />
      </div>
      <div className="poweredBy bottomContent">
        <h2>
          Powered by Bose
          <span style={{ fontSize: "14px" }}>®</span>
        </h2>
      </div>
      <div
        role="button"
        tabIndex={0}
        className="privacyButton bottomContent"
        onClick={() => {
          // sendAnalyticsCall({
          //   buttonType: "noisecancelling-demo-click",
          //   buttonName: "noisecancelling-demo-privacy-overlay-open",
          //   analytics: audio.analytics,
          // });
          dispatchReseller({ type: "showPrivacyPolicy" })
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            // sendAnalyticsCall({
            //   buttonType: "noisecancelling-demo-click",
            //   buttonName: "noisecancelling-demo-privacy-overlay-open",
            //   analytics: audio.analytics,
            // });
            dispatchReseller({ type: "showPrivacyPolicy" })
          }
        }}
        style={{ display: setAnalytics ? "flex" : "none" }}>
        <h2>{t("composer.privacy")}</h2>
      </div>
    </div>
  )
}
export default ResellerBar
