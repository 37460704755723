import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

const supported = [
  "de-DE",
  "en-CA",
  "en-GB",
  "en-US",
  "en-AE",
  "en-AU",
  "en-HK",
  "en-IN",
  "en-IE",
  "en-NZ",
  "en-SG",
  "fr-CA",
  "fr-CH",
  "fr-LU",
  "fr-BE",
  "fr-FR",
  "de-AT",
  "de-CH",
  "zh-HK",
  "zh-TW",
  "zh-CN",
  "da-DK",
  "nl-NL",
  "nl-BE",
  "fi-FI",
  "hu-HU",
  "it-IT",
  "it-CH",
  "ja-JP",
  "nb-NO",
  "pl-PL",
  "es-ES",
  "es-MX",
  "es-AR",
  "es-CL",
  "es-CO",
  "es-PE",
  "sv-SE",
  "vi-VN",
]

const resources = {
  "en-US": {
    translation: require("../data/translations/en-US.json"),
  },
  "en-AE": {
    translation: require("../data/translations/en-AE.json"),
  },
  "en-GB": {
    translation: require("../data/translations/en-GB.json"),
  },
  "en-IE": {
    translation: require("../data/translations/en-IE.json"),
  },
  "en-IN": {
    translation: require("../data/translations/en-IN.json"),
  },
  "en-CA": {
    translation: require("../data/translations/en-CA.json"),
  },
  "nl-NL": {
    translation: require("../data/translations/nl-NL.json"),
  },
  "nl-BE": {
    translation: require("../data/translations/nl-BE.json"),
  },
  "en-HK": {
    translation: require("../data/translations/en-HK.json"),
  },
  "en-AU": {
    translation: require("../data/translations/en-AU.json"),
  },
  "en-NZ": {
    translation: require("../data/translations/en-NZ.json"),
  },
  "en-SG": {
    translation: require("../data/translations/en-SG.json"),
  },
  "de-DE": {
    translation: require("../data/translations/de-DE.json"),
  },
  "fr-CA": {
    translation: require("../data/translations/fr-CA.json"),
  },
  "fr-FR": {
    translation: require("../data/translations/fr-FR.json"),
  },
  "fr-CH": {
    translation: require("../data/translations/fr-CH.json"),
  },
  "fr-LU": {
    translation: require("../data/translations/fr-LU.json"),
  },
  "fr-BE": {
    translation: require("../data/translations/fr-BE.json"),
  },
  "de-AT": {
    translation: require("../data/translations/de-AT.json"),
  },
  "de-CH": {
    translation: require("../data/translations/de-CH.json"),
  },
  "ja-JP": {
    translation: require("../data/translations/ja-JP.json"),
  },
  "fi-FI": {
    translation: require("../data/translations/fi-FI.json"),
  },
  "hu-HU": {
    translation: require("../data/translations/hu-HU.json"),
  },
  "sv-SE": {
    translation: require("../data/translations/sv-SE.json"),
  },
  "nb-NO": {
    translation: require("../data/translations/nb-NO.json"),
  },
  "vi-VN": {
    translation: require("../data/translations/vi-VN.json"),
  },
  "zh-TW": {
    translation: require("../data/translations/zh-TW.json"),
  },
  "zh-HK": {
    translation: require("../data/translations/zh-HK.json"),
  },
  "zh-CN": {
    translation: require("../data/translations/zh-CN.json"),
  },
  "es-ES": {
    translation: require("../data/translations/es-ES.json"),
  },
  "es-MX": {
    translation: require("../data/translations/es-MX.json"),
  },
  "es-AR": {
    translation: require("../data/translations/es-AR.json"),
  },
  "es-PE": {
    translation: require("../data/translations/es-PE.json"),
  },
  "es-CO": {
    translation: require("../data/translations/es-CO.json"),
  },
  "es-CL": {
    translation: require("../data/translations/es-CL.json"),
  },
  "it-IT": {
    translation: require("../data/translations/it-IT.json"),
  },
  "it-CH": {
    translation: require("../data/translations/it-CH.json"),
  },
  "da-DK": {
    translation: require("../data/translations/da-DK.json"),
  },
  "pl-PL": {
    translation: require("../data/translations/pl-PL.json"),
  },
}
const options = {
  order: ["path"],
  //order: ["path", "subdomain", "navigator"],
  lookupQuerystring: "lng",
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    // lng: "en-US",
    fallbackLng: "en-US",
    detection: options,
    supportedLngs: supported,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })
export default i18n
