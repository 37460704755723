import { useMemo } from "react"
import { useParams } from "react-router-dom"
import queryString from "query-string"

export default function useRouter() {
  const params = useParams()
  return useMemo(() => {
    return {
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search),
        ...params,
      },
      location,
    }
  }, [params])
}
