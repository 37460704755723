/* eslint-disable no-param-reassign */
import { useEffect, useState } from "react"
import axios from "axios"
import i18n from "i18next";
import ReactHtmlParser from "react-html-parser"
import { useTranslation } from "react-i18next"
import { useReseller } from "../context/resellerContext"

const PrivacyOverlay = () => {
  const [privacyPolicyHtml, setPrivacyPolicyHtml] = useState("")
  const [{ showPrivacyPolicy }, dispatchReseller] = useReseller()
  const { t } = useTranslation()
  const contains = (selector, context, exp) => {
    const elements = context.querySelectorAll(selector)
    return Array.prototype.filter.call(elements, (element) => RegExp(exp).test(element))
  }

  const localeList = ["en-US", "en-CA", "fr-CA"];

  const getPrivatePolicyTarget = () => {
    if (localeList.includes(i18n.language)) {
      return `generic-page__sections-holder`;
    } else return `column`;
  };
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!showPrivacyPolicy) return "";

    (async () => {
      const parser = new DOMParser();
      const privacyRequest = await axios.get(
        `/api/cors?uri=${t("composer.legalLink")}`
      );
      const privacyData = await privacyRequest.data;
      const parseDoc = parser.parseFromString(privacyData, "text/html");
      const backToLegal = contains("a", parseDoc, "/legal");
      const contacts = contains("a", parseDoc, "/email-protection");
      const preferences = contains("a", parseDoc, "open_cookie_preferences");
      const cookieLink = contains("a", parseDoc, "/legal/cookies-notice");
      const topAnchor = contains("a", parseDoc, "#maincontent");
      const doubleAnchors = contains("a", parseDoc, "https//");
      const allAnchors = contains("a", parseDoc, "");

      topAnchor.forEach((a) => {
        a.href = "#Introduction";
        // a.innerHTML = "";
        a.style.textDecoration = "underline";
      });

      doubleAnchors.forEach((a) => {
        const originalLink = a.href;
        const removeDouble = "https//";
        const newLink = originalLink.replace(removeDouble, "");
        a.href = `${newLink}`;
      });

      backToLegal.forEach((a) => {
        a.href = "";
        a.innerHTML = "";
      });
      contacts.forEach((a) => {
        a.href = "javascript:void(0)";
        a.target = "_blank";
        a.innerHTML = "privacyandsecurity@bose.com";
      });
      preferences.forEach((a) => {
        a.href = "javascript:void(0)";
        a.target = "_blank";
      });

      cookieLink.forEach((a) => {
        // a.href = `${t("composer.cookieLink")}`;
        a.href = "";
        a.target = "_blank";
      });

      allAnchors.forEach((a) => {
        const res =
          a.href.includes("adobe") ||
          a.href.includes("google") ||
          a.href.includes("bosecreative") ||
          a.href.includes("#Introduction") ||
          a.href.includes("preferences");
        if (!res) {
          a.href = "javascript:void(0)";
        }
        if (res) {
          a.style.textDecoration = "underline";
        }
        // a.style.textDecoration = "underline";
        // a.innerHTML = "";
      });

      const html = parseDoc.getElementsByClassName(
        `${getPrivatePolicyTarget()}`
      )[0].outerHTML;

      return setPrivacyPolicyHtml(html);
    })();
  }, [t, showPrivacyPolicy]);

  const setPrivacy = () => {
    dispatchReseller({ type: "showPrivacyPolicy" })
  }

  return (
    <div aria-modal="true" className={`privacyOverlay ${showPrivacyPolicy ? "privacyOverlay-shown" : "privacyOverlay-hidden"}`}>
      <div className="policy">
        <div
          role="button"
          className="privacyHide"
          tabIndex={0}
          onClick={() => {
            // sendAnalyticsCall({
            //   buttonType: "soudbardemo-click",
            //   buttonName: "soudbardemo-privacy-overlay-close",
            //   analytics: audio.analytics,
            // });
            setPrivacy()
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              // sendAnalyticsCall({
              //   buttonType: "soudbardemo-click",
              //   buttonName: "soudbardemo-privacy-overlay-close",
              //   analytics: audio.analytics,
              // });
              setPrivacy()
            }
          }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 5.014 7.476"
            style={{
              stroke: "black",
              strokeWidth: "0.5",
            }}>
            <defs />
            <path d="M.334,6.1,0,5.784,2.895,3.05,0,.316.334,0,3.558,3.05Z" transform="translate(4.286 6.788) rotate(180)" />
          </svg>
        </div>
        {ReactHtmlParser(privacyPolicyHtml)}
      </div>
      <button
        type="button"
        tabIndex={0}
        onClick={() => {
          // sendAnalyticsCall({
          //   buttonType: "soudbardemo-click",
          //   buttonName: "soudbardemo-privacy-overlay-close",
          //   analytics: audio.analytics,
          // });
          setPrivacy()
        }}>
        {t("composer.closeButton")}
      </button>
    </div>
  )
}
export default PrivacyOverlay
