import { createContext, useReducer, useMemo, useContext, useEffect } from "react"
import { UAParser } from "ua-parser-js"
import { deviceType } from "react-device-detect"
import { sendAnalyticsCall } from "../services/analytics"
import useRouter from "../hooks/useRouter"

const ResellerContext = createContext()
const parser = new UAParser()
const parserResults = parser.getResult()
const device = deviceType

function isCrossOriginFrame() {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

function getParent() {
  try {
    return window.location !== window.parent.location ? document.referrer : document.location.href
  } catch (e) {
    return undefined
  }
}
const fireAnalytics = (state, action) => {
  if (!state.initialized) {
    sendAnalyticsCall({
      type: "impression",
      buttonName: "activesenseaware-demo-impression",
      buttonType: "activesenseaware-demo-impression",
      buttonPosition: "center",
      vendor: state.reseller ? "reseller" : "global",
      browser: `${state.browser}`,
      device: state.deviceType,
      sendAnalytics: state.setAnalytics,
      direct: state.direct,
      parent: state.parent,
    })
    return
  }

  if (state.initialized && !state.interacted) {
    sendAnalyticsCall({
      type: "engaged",
      buttonName: "activesenseaware-demo-engaged",
      buttonType: "activesenseaware-demo-engaged",
      buttonPosition: "center",
      vendor: state.reseller ? "reseller" : "global",
      browser: `${state.browser}`,
      device: state.deviceType,
      sendAnalytics: state.setAnalytics,
      direct: state.direct,
      parent: state.parent,
    })
  }
  if (state.initialized && action[0] === "button") {
    sendAnalyticsCall({
      type: action[0],
      buttonName: `activesenseaware-demo-${action[1]}`,
      buttonType: "activesenseaware-demo-click",
      buttonPosition: "center",
      vendor: state.reseller ? "reseller" : "global",
      browser: `${state.browser}`,
      device: state.deviceType,
      direct: state.direct,
      parent: state.parent,
      sendAnalytics: state.setAnalytics,
    })
    return
  }
  if (state.initialized && action[0] === "duration") {
    if (action[2][2] < 3 || action[2][1] > 5) {
      return
    }
    sendAnalyticsCall({
      type: action[0],
      buttonName: "activesenseaware-demo-duration",
      buttonType: "activesenseaware-demo-duration",
      buttonPosition: "center",
      duration: `${action[2][1] * 60 + action[2][2]}`,
      vendor: state.reseller ? "reseller" : "global",
      browser: `${state.browser}`,
      device: state.deviceType,
      direct: state.direct,
      parent: state.parent,
      sendAnalytics: state.setAnalytics,
    })
  }
}
function reducer(state, action) {
  switch (action.type) {
    case "locale": {
      return { ...state, locale: action.payload }
    }
    case "inView": {
      if (state.interacted && action.payload[1] === false) {
        fireAnalytics(state, action.payload)
      }
      return state
    }
    case "wasPlaying": {
      return { ...state, wasPlaying: action.payload }
    }
    case "hide": {
      return { ...state, hide: action.payload }
    }
    case "isCatalina": {
      return { ...state, catalina: action.payload }
    }
    case "resellerMode": {
      return { ...state, reseller: true }
    }
    case "languageSelector": {
      return { ...state, languageSelector: true }
    }
    case "showLanguageSelector": {
      if (state.showLanguageSelector) {
        return { ...state, showLanguageSelector: false }
      }
      return { ...state, showLanguageSelector: true }
    }
    case "initialized": {
      fireAnalytics(state)
      if (!state.initialized) {
        return { ...state, initialized: true }
      }
      return state
    }
    case "showPrivacyPolicy": {
      if (state.showPrivacyPolicy) {
        return { ...state, showPrivacyPolicy: false }
      }
      return { ...state, showPrivacyPolicy: true }
    }
    case "setAnalytics": {
      return { ...state, setAnalytics: true }
    }
    case "analytics": {
      if (!state.initialized) {
        fireAnalytics(state, action.payload)
      }
      if (state.initialized && !state.interacted) {
        fireAnalytics(state, action.payload)
        return { ...state, interacted: true }
      }

      fireAnalytics(state, action.payload)
      return state
    }
    default:
      return state
  }
}

const ResellerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    languageSelector: false,
    showPrivacyPolicy: false,
    inView: false,
    showLanguageSelector: false,
    reseller: false,
    catalina: false,
    hide: "",
    initialized: false,
    interacted: false,
    setAnalytics: false,
    wasPlaying: false,
    deviceType: device === "browser" ? "Desktop" : device,
    browser: `${parserResults.browser.name} ${parserResults.browser.version}`,
    direct: isCrossOriginFrame(),
    parent: getParent(),
  })

  const value = useMemo(() => ({ state, dispatch }), [state])
  const {
    query: { r, showLanguageSelector, analytics, hide },
    locale,
  } = useRouter()

  useEffect(() => {
    if (r === "true") {
      dispatch({ type: "resellerMode" })
    }
    if (analytics === "true") {
      dispatch({ type: "setAnalytics" })
    }
    if (showLanguageSelector === "true") {
      dispatch({ type: "languageSelector" })
    }
    if (locale) {
      dispatch({ type: "locale", payload: locale })
    }
    if (hide) {
      dispatch({ type: "hide", payload: hide })
    }
  }, [r, showLanguageSelector, locale, analytics])

  useMemo(() => {
    if (parserResults.browser.name === "Safari" && parserResults.browser.version === "15.0") {
      dispatch({ type: "isCatalina", payload: true })
    }
  }, [])

  return <ResellerContext.Provider value={value}>{children}</ResellerContext.Provider>
}

function useReseller() {
  const context = useContext(ResellerContext)
  if (context === undefined) {
    throw new Error("useReseller must be used within a ResellerProvider")
  }
  return [context.state, context.dispatch]
}

export { ResellerProvider, useReseller }
