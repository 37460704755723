import { useLoader } from "@react-three/fiber"
import { GLTFLoader, DRACOLoader, MeshoptDecoder } from "three-stdlib"

// @ts-ignore

function extensions(useDraco, useMeshopt) {
  return (loader) => {
    if (useDraco) {
      const dracoLoader = new DRACOLoader()
      dracoLoader.setDecoderPath(typeof useDraco === "string" ? useDraco : "https://www.gstatic.com/draco/versioned/decoders/1.5.3/")
      loader.setDRACOLoader(dracoLoader)
    }

    if (useMeshopt) {
      loader.setMeshoptDecoder(MeshoptDecoder)
    }
  }
}

function useGLTF(path, useDraco = true, useMeshOpt = true) {
  const gltf = useLoader(GLTFLoader, path, extensions(useDraco, useMeshOpt))
  return gltf
}

useGLTF.preload = (path, useDraco = true, useMeshOpt = true) => useLoader.preload(GLTFLoader, path, extensions(useDraco, useMeshOpt))

export { useGLTF }
