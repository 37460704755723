import React from "react"
import ReactDOM from "react-dom"
import "./styles.scss"
import App from "./App"
import { ResellerProvider } from "./context/resellerContext"

ReactDOM.render(
  <ResellerProvider>
    <App />
  </ResellerProvider>,
  document.getElementById("root"),
)
