const getEvent = (type, vendor, duration) => {
  switch (type) {
    case "button": {
      return vendor === "reseller" ? "event1" : "event405"
    }
    case "impression": {
      return vendor ? "event2" : "event2"
    }
    case "engaged": {
      return vendor ? "event3" : "event3"
    }
    case "duration": {
      return vendor ? `event4=${duration}` : `event4=${duration}`
    }
    default:
      return type
  }
}

export const sendAnalyticsCall = ({
  type = "",
  buttonName = "",
  buttonType = "",
  buttonPosition = "",
  buttonUrl = "#",
  parent = "",
  duration = "",
  device = "",
  browser = "",
  product = "",
  direct = true,
  language = "en",
  country = "US",
  vendor = "global",
  sendAnalytics = false,
  pageType = "activesenseaware-demo",
  isButton = true,
}) => {
  if (!sendAnalytics) {
    return
  }
  if (window.location.hostname === "localhost") {
    return
  }
  const eventNumber = getEvent(type, vendor, duration)

  const ENDPOINT =
    vendor === "reseller"
      ? "https://emetrics.bose.com/b/ss/boseboseb2c-reseller-data-prod/10/JS-2.10.0-LAS8/s67564152107642"
      : "https://emetrics.bose.com/b/ss/bosecom/10/JS-2.10.0-LAWA/s13500475447491"
  const TODAY = new Date()
  const DAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
  const CURRENT_TIME = `${TODAY.getHours()}:${TODAY.getMinutes()}:${TODAY.getSeconds()}`
  const CURRENT_DAY = DAYS[TODAY.getDay()]
  const CURRENT_DAY_DIGIT = `0${TODAY.getDay()}`.slice(-2)
  const CURRENT_MONTH_DIGIT = `0${TODAY.getMonth()}`.slice(-2)
  const CURRENT_DATE = `${TODAY.getFullYear()}/${CURRENT_MONTH_DIGIT}/${CURRENT_DAY_DIGIT}`
  const PATHNAME = window.location.pathname

  const buttonParams = {
    global: {
      v127: `${buttonType}|${buttonName}|${buttonPosition}|${buttonUrl}`,
    },
    reseller: {
      v1: `${buttonType}|${buttonName}|${buttonPosition}|${buttonUrl}`,
    },
  }

  const queryParams = {
    global: {
      v168: "ActiveSense Aware Noise Cancelling Demo",
      v169: direct,
      AQB: 1,
      pccr: true,
      vidn: "2F5340598515ACB8-60000BBC786DFEBE",
      pageName: "activesenseaware-demo",
      g: window.location.href,
      ch: "activesenseaware-demo",
      events: eventNumber.toString(),
      c16: country,
      v16: country,
      c18: "B2C",
      v18: "B2C",
      c34: language,
      v34: language,
      c53: window.location.href,
      v60: vendor === "reseller" ? "Reseller_Data" : "Global_Digital",
      v62: window.location.href,
      v64: window.location.href,
      v65: pageType,
      c74: pageType,
      v77: CURRENT_TIME,
      v78: CURRENT_DAY,
      v79: CURRENT_DATE,
      v112: "prod--2",
      v123: window.location.href,
      v124: PATHNAME,
      pev2: isButton ? "button-click" : "contentblock-impression",
      pe: "lnk_o",
      AQE: 1,
    },
    reseller: {
      v2: parent,
      v3: "ActiveSense Aware Noise Cancelling Demo",
      v4: device,
      v5: browser,
      v6: product,
      v7: direct,
      AQB: 1,
      pccr: true,
      vidn: "2F5340598515ACB8-60000BBC786DFEBE",
      pageName: "activesenseaware-demo",
      g: window.location.href,
      ch: "activesenseaware-demo",
      events: eventNumber.toString(),
      c16: country,
      v16: country,
      c18: "B2C",
      v18: "B2C",
      c34: language,
      v34: language,
      c53: window.location.href,
      v60: vendor === "reseller" ? "Reseller_Data" : "Global_Digital",
      v62: window.location.href,
      v64: window.location.href,
      v65: pageType,
      c74: pageType,
      v77: CURRENT_TIME,
      v78: CURRENT_DAY,
      v79: CURRENT_DATE,
      v112: "prod--2",
      v123: window.location.href,
      v124: PATHNAME,
      pev2: isButton ? "button-click" : "contentblock-impression",
      pe: "lnk_o",
      AQE: 1,
    },
  }
  const params = isButton ? { ...buttonParams[vendor], ...queryParams[vendor] } : { ...queryParams[vendor] }

  const PARAMS = Object.keys(params)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join("&")

  try {
    fetch(`${ENDPOINT}?${PARAMS}`, {})
  } catch {
    console.log("Analytics failed")
  }
}
