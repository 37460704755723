export const languages = [
  {
    language: "Deutsch ",
    country: "Austria",
    iso: "AT",
    lng: "de-AT",
    meta: "Österreich, Osterreich, Austria, German",
  },
  {
    language: "Deutsch ",
    country: "Switzerland",
    iso: "CH",
    lng: "de-CH",
    meta: "Schweiz, Switzerland, German",
  },
  {
    language: "Deutsch ",
    country: "Germany",
    iso: "DE",
    lng: "de-DE",
    meta: "Deutschland, Germany, German",
  },
  // {
  //   language: "简体中文 ",
  //   country: "China",
  //   iso: "CN",
  //   lng: "zh-CN",
  //   meta: "China, 中国, 简体中文, Chinese",
  // },
  {
    language: "Dansk ",
    country: "Denmark",
    iso: "DK",
    lng: "da-DK",
    meta: "Denmark, Danmark, Dansk, Danish",
  },
  {
    language: "English ",
    country: "United-Arab-Emirates",
    iso: "AE",
    lng: "en-AE",
    meta: "United-Arab-Emirates, English, UAE",
  },
  {
    language: "English ",
    country: "Australia",
    iso: "AU",
    lng: "en-AU",
    meta: "Australia, English",
  },
  {
    language: "English ",
    country: "Canada",
    iso: "CA",
    lng: "en-CA",
    meta: "Canada, English",
  },
  {
    language: "English ",
    country: "United-Kingdom",
    iso: "GB",
    lng: "en-GB",
    meta: "United Kingdom, United-Kingdom, English",
  },
  {
    language: "English ",
    country: "Hong-Kong",
    iso: "HK",
    lng: "en-HK",
    meta: "Hong Kong, Hong-Kong, English",
  },
  {
    language: "English ",
    country: "India",
    iso: "IN",
    lng: "en-IN",
    meta: "India, English",
  },
  {
    language: "English ",
    country: "Ireland",
    iso: "IE",
    lng: "en-IE",
    meta: "Ireland, English",
  },
  {
    language: "English ",
    country: "New-Zealand",
    iso: "NZ",
    lng: "en-NZ",
    meta: "New-Zealand, New Zealand, English",
  },
  {
    language: "English ",
    country: "Singapore",
    iso: "SG ",
    lng: "en-SG",
    meta: "Singapore, English",
  },
  {
    language: "English ",
    country: "United-States",
    iso: "US",
    lng: "en-US",
    meta: "United States, United-States, English",
  },
  {
    language: "Español ",
    country: "Argentina",
    iso: "AR",
    lng: "es-AR",
    meta: "Argentina, Español, Espanol, Spanish",
  },
  {
    language: "Español ",
    country: "Chile",
    iso: "CL",
    lng: "es-CL",
    meta: "Chile, Español, Espanol, Spanish",
  },
  {
    language: "Español ",
    country: "Colombia",
    iso: "CO",
    lng: "es-CO",
    meta: "Colombia, Español, Espanol, Spanish",
  },
  {
    language: "Español ",
    country: "Spain",
    iso: "ES",
    lng: "es-ES",
    meta: "España, Spain, Español, Espanol, Spanish",
  },
  {
    language: "Español ",
    country: "Mexico",
    iso: "MX",
    lng: "es-MX",
    meta: "México, Mexico, Español, Espanol, Spanish",
  },
  {
    language: "Español ",
    country: "Peru",
    iso: "PE",
    lng: "es-PE",
    meta: "Perú, Peru, Español, Espanol, Spanish",
  },
  {
    language: "Francais ",
    country: "Belgium",
    iso: "BE",
    lng: "fr-BE",
    meta: "Belgique, Belgium, François, Francois, French",
  },
  {
    language: "Francais ",
    country: "Canada",
    iso: "CA",
    lng: "fr-CA",
    meta: "Canada, Canada, François, Francois, French",
  },
  {
    language: "Francais ",
    country: "Switzerland",
    iso: "CH",
    lng: "fr-CH",
    meta: "Suisse, Switzerland, François, Francois, French",
  },
  {
    language: "Francais ",
    country: "France",
    iso: "FR",
    lng: "fr-FR",
    meta: "France, France, François, Francois, French",
  },
  {
    language: "Francais ",
    country: "Luxembourg",
    iso: "LU",
    lng: "fr-LU",
    meta: "France, Luxemburg, François, Francois, French",
  },
  {
    language: "Italiano ",
    country: "Italy",
    iso: "IT",
    lng: "it-IT",
    meta: "Italia, Italy, Italiano, Italian",
  },
  {
    language: "Italiano ",
    country: "Switzerland",
    iso: "CH",
    lng: "it-CH",
    meta: "Svizzera, Switzerland, Italiano, Italian",
  },
  {
    language: "日本語 ",
    country: "Japan",
    iso: "JP",
    lng: "ja-JP",
    meta: "Japan, 日本, 日本語, Japanese",
  },
  {
    language: "Magyar ",
    country: "Hungary",
    iso: "HU",
    lng: "hu-HU",
    meta: "Magyar, Magyarország, Hungary, Hungarian",
  },
  {
    language: "Nederlands ",
    country: "Netherlands",
    iso: "NL",
    lng: "nl-NL",
    meta: "Nederland, Netherlands, Dutch",
  },
  {
    language: "Nederlands ",
    country: "Belgium",
    iso: "BE",
    lng: "nl-BE",
    meta: "België, Belgie, Netherlands, Dutch",
  },
  {
    language: "Norsk ",
    country: "Norway",
    iso: "NO",
    lng: "nb-NO",
    meta: "Norway, Norge, Norsk, Norwegian",
  },
  {
    language: "Polski ",
    country: "Poland",
    iso: "PL",
    lng: "pl-PL",
    meta: "Poland, Polska, Polski, Polish",
  },
  {
    language: "Suomi ",
    country: "Finland",
    iso: "FI",
    lng: "fi-FI",
    meta: "Finland, Suomi, Finish",
  },
  {
    language: "Svenska ",
    country: "Sweden",
    iso: "SE",
    lng: "sv-SE",
    meta: "Sweden, Sverige, Svenska, Swedish",
  },
  {
    language: "中文 ",
    country: "Hong-Kong",
    iso: "HK",
    lng: "zh-HK",
    meta: "Hong-Kong, Hong Kong, 中文",
  },
  {
    language: "繁體中文 ",
    country: "Taiwan",
    iso: "TW",
    lng: "zh-TW",
    meta: "Taiwan, 繁體中文",
  },
  {
    language: "Tiếng Việt ",
    country: "Vietnam",
    iso: "VN",
    lng: "vi-VN",
    meta: "Vietnam, Tiếng Việt, Vietnamese",
  },
];
